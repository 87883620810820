<!-- =========================================================================================
  File Name: HobjectList.vue
  Description: Object List - Thumb View 
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <!-- <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" /> -->
    <init-data></init-data>
    <!-- {{ this.hobjects }} -->

    <vs-table
      noDataText="It seems that you don't have any object yet, to create one click on 'Add Object'"
      ref="table"
      v-model="selected"
      pagination
      :max-items="itemsPerPage"
      search
      :data="hobjects"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center">
          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">

                    <div class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32">
                        <span class="mr-2">Actions</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>

                    <vs-dropdown-menu>

                        <vs-dropdown-item>
                            <span class="flex items-center">
                                <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                                <span>Delete</span>
                            </span>
                        </vs-dropdown-item>

                        <vs-dropdown-item>
                            <span class="flex items-center">
                                <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                                <span>Archive</span>
                            </span>
                        </vs-dropdown-item>

                        <vs-dropdown-item>
                            <span class="flex items-center">
                                <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                                <span>Print</span>
                            </span>
                        </vs-dropdown-item>

                        <vs-dropdown-item>
                            <span class="flex items-center">
                                <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                                <span>Another Action</span>
                            </span>
                        </vs-dropdown-item>

                    </vs-dropdown-menu>
                </vs-dropdown> -->

          <!-- ADD NEW -->
          <div
            class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
            @click="addNewHobject"
          >
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('AddObject') }}</span>
          </div>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{ hobjects.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : hobjects.length }} of
              {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="model_identifier">Type</vs-th>
        <vs-th sort-key="image">Thumbnail</vs-th>
        <vs-th sort-key="name">{{ $t('Name') }}</vs-th>
        <vs-th sort-key="status">{{ $t('Usedin') }}</vs-th>
        <vs-th sort-key="updated_at_timestamp">Date modified</vs-th>
        <vs-th sort-key="content_length">{{ $t('Size') }}</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="hobject" :key="indextr" v-for="(hobject, indextr) in data">
            <vs-td class="img-container">
              <img :src="getModelThumbnail(hobject)" class="product-img rounded-lg" />
            </vs-td>
            <vs-td class="img-container">
              <img :src="getHobjectThumbnail(hobject)" class="product-img rounded-lg" />
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ hobject.name }}</p>
              <p class="product-category">{{ hobject.description }}</p>
            </vs-td>
            <vs-td>
              <div v-if="getNodesFromHobject(hobject).length > 0">
                <vs-chip
                  :data="node"
                  :key="indextr"
                  v-for="(node, indextr) in removeDuplicatesNodesWithSameSpace(getNodesFromHobject(hobject))"
                  link
                  color="primary"
                  class="product-order-status"
                  ><router-link v-if="getSpaceFromNode(node).name" :to="createSpaceLink(node)">
                    {{ getSpaceFromNode(node).name | truncate(30, '...') }} </router-link
                  ><router-link v-else :to="createSpaceLink(node)"> Unamed spaces </router-link></vs-chip
                >
              </div>
              <vs-chip v-else link color="warning" class="product-order-status"> inactive </vs-chip>
            </vs-td>
            <vs-td>
              <p class="font-medium truncate">
                {{ dateToString(hobject.updated_at) }}
              </p>
            </vs-td>
            <vs-td>
              <p class="product-name font-medium truncate">{{ humanFileSize(hobject) }}</p>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <feather-icon
                v-if="hobject.model_identifier != 'hoverlay.core.UnityAssetBundle.1.0'"
                size="3x"
                icon="DownloadIcon"
                svgClasses="w-7 h-7 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="downloadHobject(hobject)"
              />
              <div v-else style="display: inline-flex; align-items: center;" class="mr-8"></div>
              <feather-icon
                size="3x"
                icon="EditIcon"
                svgClasses="w-7 h-7 hover:text-primary stroke-current"
                class="ml-2"
                @click.stop="editHobject(hobject.pid)"
              />
              <feather-icon
                size="3x"
                icon="TrashIcon"
                svgClasses="w-7 h-7 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="deleteHobjectWithConfirmation(hobject.pid)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import InitData from '../../components/InitData.vue'
import * as Utils from '@/assets/js/utils/utils.js'
import date from 'date-and-time'

export default {
  components: {
    InitData,
  },
  data() {
    return {
      selected: [],
      itemsPerPage: 15,
      isInitialized: false,
      isMounted: false,
      Youtube: null,
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    hobjects() {
      var h = this.$store.state.hoverlay.hobjects
      if (this.$store.state.hoverlay) {
        return h.sort(this.compareDate)
      } else {
        return null
      }
    },
    nodes() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.nodes
      } else {
        return null
      }
    },
    spaces() {
      if (this.$store.state.hoverlay) {
        // console.log(this.$store.state.hoverlay.spaces)
        return this.$store.state.hoverlay.spaces
      } else return null
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.hobjects.length
    },
  },
  methods: {
    async downloadHobject(hobject) {
      console.log(hobject)
      const a = document.createElement('a')
      a.href = await this.toDataURL(hobject.asset_uri)
      if (hobject.model_identifier == 'hoverlay.core.3dModel.1.0') a.download = hobject.name + '.glb'
      if (hobject.model_identifier == 'hoverlay.core.Image.1.0') a.download = hobject.name + '.png'
      if (hobject.model_identifier == 'hoverlay.core.Video.1.0') a.download = hobject.name + '.mp4'
      if (hobject.model_identifier == 'hoverlay.core.ChromaVideo.1.0') a.download = hobject.name + '.mp4'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    async toDataURL(url) {
      const blob = await fetch(url).then(res => res.blob())
      return URL.createObjectURL(blob)
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    deleteHobjectWithConfirmation(hobject_pid) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('DeleteHobjectPermanentlyTitle'),
        text: this.$t('DeleteHobjectPermanentlyDescription'),
        acceptText: this.$t('Yes'),
        cancelText: this.$t('No'),
        accept: () => {
          this.deleteHobject(hobject_pid)
        },
      })
    },
    // deleteHobject(hobject_pid) {
    deleteHobject(hobject_pid) {
      var spacePid = null
      var space = this.spaces.find(space => space.hobject_pid === space.hobject_pid)
      if (space && space.length > 0) spacePid = space.pid

      this.$store
        .dispatch('hoverlay/removeHobject', {
          hobject_pid: hobject_pid,
        })
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: this.$t('ObjectRemoved'),
            color: 'success',
            icon: 'check_box',
          })

          // console.log('SUCCESS')
        })
        .catch(error => {
          // console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    addNewHobject() {
      // console.log('createNewHobject')
      this.$router.push({
        path: `/create/`,
      })
    },
    getHobjectThumbnail(hobject) {
      if (hobject.cropped_image) return hobject.cropped_image
    },
    getModelThumbnail(hobject) {
      var model = this.$store.state.hoverlay.models.find(model => model.pid == hobject.model_pid)
      if (model && model.thumbnail_small) return model.thumbnail_small
      else return null
    },

    getModelDispayName(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.modelPid)[0].name
      return model
    },
    getModelDescription(hobject) {
      var model = this.$store.state.hoverlay.models.filter(model => model.pid === hobject.modelPid)[0].description
      return model
    },
    isHobjectPlaced(hobject) {
      var space = this.$store.state.hoverlay.spaces.filter(space => space.hobject_pid === hobject.pid)
      if (space && space.length > 0) return true
      else return false
    },
    getSpaceFromNode(node) {
      // console.log('getSpaceFromNode')
      // console.log(node.pid)
      // console.log(this.spaces.find(space => space.pid == node.space_pid))
      return this.spaces.find(space => space.pid == node.space_pid)
    },
    createSpaceLink(node) {
      var space_pid = this.spaces.find(space => space.pid == node.space_pid).pid
      return `/space/${space_pid}`
    },
    getNodesFromHobject(hobject) {
      return this.nodes.filter(node => node.hobject_pid === hobject.pid)
    },
    removeDuplicatesNodesWithSameSpace(nodes) {
      return nodes.filter((v, i, a) => a.findIndex(t => t.space_pid === v.space_pid) === i)
    },
    getPopularityColor(num) {
      if (num > 90) return 'success'
      if (num > 70) return 'primary'
      if (num >= 50) return 'warning'
      if (num < 50) return 'danger'
      return 'primary'
    },
    // toggleDataSidebar(val = false) {
    //   this.addNewDataSidebar = val
    // },
    humanFileSize(hobject) {
      return Utils.humanFileSize(hobject.content_length, true)
    },
    dateToString(time) {
      return date.format(new Date(time), 'ddd, MMM DD YYYY') // => 'Fri, Jan 02 2015'
    },
    compareDate(a, b) {
      if (a.updated_at_timestamp < b.updated_at_timestamp) {
        return 1
      }
      if (a.updated_at_timestamp > b.updated_at_timestamp) {
        return -1
      }
      return 0
    },
  },

  async created() {},
  async mounted() {
    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯
      this.isMounted = true
      if (this.hobjects.length <= 0) this.$vs.loading()
    })
    await this.$store.dispatch('hoverlay/getLayerContent')
    this.$vs.loading.close()
  },
}
</script>

<style lang="scss">
#data-list-thumb-view .vs-con-table .vs-table tr td.img-container .product-img {
  height: 80px !important;
}
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
}
</style>
